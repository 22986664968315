
import { defineComponent } from "vue";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Button from "primevue/button";
import { mapGetters, mapActions } from "vuex";
import Utils from "@/utility/utils";
import { cloneDeep } from "lodash";

export default defineComponent({
  components: {
    TabPanel,
    TabView,
    Button,
  },
  computed: {
    ...mapGetters({
      getActiveCustomerOpenSalesOpps:
        "customerInquiry/getActiveCustomerOpenSalesOpps",
      getActiveTab: "customerInquiry/getActiveTab",
      getActiveIndexCustomer: "customerInquiry/getActiveIndexCustomer",
    }),
  },
  methods: {
    ...mapActions({
      changeActiveMainTab: "customerInquiry/changeActiveTab",
      removeSalesOpp: "customerInquiry/removeSalesOpp",
      setActiveTab: "customerInquiry/setActiveTab",
    }),
    formatTabId(tab: any) {
      const id = tab.id;
      return id.includes("New") ? `#${id.split("-")[1]}` : `#${id}`;
    },
    hasChanged(opportunities: any) {
      const oldRecord = cloneDeep(opportunities.old_record || {});

      const record = cloneDeep(opportunities.record);
      delete record.oldRecord;

      if (record.id && record.id.includes("New")) {
        return true;
      }

      const ignoreFields = [
        "saleType",
        "id",
        "lineItemsActiveTab",
        "activeInnerTab",
        "lineItemsMap",
        "customer",
        "oldRecord",
        "date",
      ];
      if (record.notes === undefined) {
        ignoreFields.push("notes");
      }
      if (record.saleType === "opportunites") {
        ignoreFields.push("rep_items");
      }

      const result = Utils.compareTwoObjects(record, oldRecord, ignoreFields);

      return result;
    },
    handleMainTabChange(e: any) {
      this.changeActiveMainTab(e.index !== undefined ? e.index : 0);
    },
    handleRemoveSaleOpp(id: string) {
      this.removeSalesOpp(id);
      if (this.getActiveCustomerOpenSalesOpps.length === 0) {
        this.setActiveTab(0);
        this.$router.push("/customers/sales/opportunities");
      } else {
        this.setActiveTab(1);
      }
    },
  },
});
